import { 
    SUCCESS_MENUS, SUCCESS_MENU_ITEM,
    SUCCESS_CUISINES, SELECT_CUISINE, SUCCESS_PLACE_CUISINES, SUCCESS_UPDATE_CUISINES 
} from './actionTypes';

const initialState = {
    featuredItems: [],
    happyHours: [],

    cuisines: [],
    placeCuisines: [],
    selectedCuisines: [],
    unSelectedCuisines: []
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        // MENUS
        case SUCCESS_MENUS:
            return {
                ...state,
                featuredItems: action.payload.featuredItems,
                happyHours: action.payload.happyHours
            }
        case SUCCESS_MENU_ITEM:
            return {
                ...state,
                featuredItems: action.payload.featuredItems,
                happyHours: action.payload.happyHours
            }
        // CUISINES
        case SUCCESS_CUISINES:
            return {
                ...state,
                cuisines: action.payload
            }
        case SUCCESS_PLACE_CUISINES:
            return {
                ...state,
                placeCuisines: action.payload.cuisines,
                selectedCuisines: action.payload.selectedCuisines
            }
        case SUCCESS_UPDATE_CUISINES:
            return {
                ...state,
                placeCuisines: action.payload,
                unSelectedCuisines: []
            }
        case SELECT_CUISINE:
            return {
                ...state,
                selectedCuisines: action.payload.selectedCuisines,
                unSelectedCuisines: action.payload.unSelectedCuisines
            }
        default:
            return state;
    }
}