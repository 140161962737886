import { 
    SUCCESS_HOLIDAY_HOURS,
    SUCCESS_PLACE_PAUSES,
    SUCCESS_CREATE_PLACE_PAUSE,
    SUCCESS_CREATE_HOLIDAY,
    SUCCESS_REMOVE_HOLIDAY
} from './actionTypes'
;
const initialState = {
    hours: [],
    totalHours: 0,
    offsetHours: 0,

    pauses: [],
    lastPause: null,
    totalPauses: 0,
    offsetPauses: 0
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SUCCESS_HOLIDAY_HOURS:
            return {
                ...state,
                hours: action.payload.holiday_hours,
                totalHours: action.payload.total
            }
        case SUCCESS_CREATE_HOLIDAY:
            const hours = [action.payload, ...state.hours];
            return {
                ...state,
                hours,
                totalHours: state.totalHours + 1
            }
        case SUCCESS_REMOVE_HOLIDAY:
            return {
                ...state,
                hours: action.payload,
                totalHours: state.totalHours - 1
            }
        case SUCCESS_PLACE_PAUSES:
            return {
                ...state,
                pauses: action.payload.data.place_pauses,
                totalPauses: action.payload.data.total,
                lastPause: action.payload.lastPause
            }
        case SUCCESS_CREATE_PLACE_PAUSE:
            const pauses = [action.payload, ...state.pauses];
            if (pauses.length > 5) {
                pauses.pop();
            }
            return {
                ...state,
                pauses,
                totalPauses: state.totalPauses + 1
            }
        default:
            return state;
    }
}