import { SUCCESS_INVOICES } from './actionTypes';

const initialState = {
    list: [],
    total: 0
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SUCCESS_INVOICES:
            return {
                ...state,
                list: action.payload.list,
                total: action.payload.total
            }
        default:
            return state;
    }
}