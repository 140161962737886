export const PENDING = "PENDING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const REJECTED = "REJECTED";
export const PASSWORD_SUCCESS = "PASSWORD_SUCCESS";
export const USER_ALREADY = "USER_ALREADY";
export const GET_SETTINGS_SUCCESS = "GET_SETTINGS_SUCCESS";
export const SPENDING_LIMITS = "SPENDING_LIMITS";
export const SUPPORTED_CITIES = "SUPPORTED_CITIES";
export const BERYMO_PAYBALANCE = "BERYMO_PAYBALANCE";

