import { SUCCESS_SCHEDULES, SUCCESS_LIMITS, UPDATE_SPENDING_LIMIT, RESET_SCHEDULE, UPDATE_DAY, SUCCESS_UPDATE_SCHEDULES } from './actionTypes';

const initialState = {
    list: [],
    spending_limit: {},
    spending_limits: [],
    totalPeople: 0,
    avg_weekly_sale: 0,
    avg_service_fee: 0,

    realList: [],
    realSpendingLimit: {},
    realTotalPeople: 0,
    real_avg_weekly_sale: 0,
    real_avg_service_fee: 0
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SUCCESS_SCHEDULES:
            return {
                ...state,
                list: [...action.payload.data.schedules],
                realList: [...action.payload.data.schedules],

                spending_limit: { ...action.payload.data.spending_limit, amount: Number(action.payload.data.spending_limit.amount).toFixed(2) },
                realSpendingLimit: action.payload.data.spending_limit,

                totalPeople: action.payload.totalPeople,
                realTotalPeople: action.payload.totalPeople,

                avg_weekly_sale: action.payload.avg_weekly_sale,
                real_avg_weekly_sale: action.payload.avg_weekly_sale,

                avg_service_fee: action.payload.avg_service_fee,
                real_avg_service_fee: action.payload.avg_service_fee
            }
        case SUCCESS_LIMITS:
            return {
                ...state,
                spending_limits: action.payload
            }
        case UPDATE_SPENDING_LIMIT:
            return {
                ...state,
                spending_limit: action.payload.limit,
                avg_weekly_sale: action.payload.avg_weekly_sale,
                avg_service_fee: action.payload.avg_service_fee
            }
        case UPDATE_DAY:
            return {
                ...state,
                list: action.payload.list,
                avg_weekly_sale: action.payload.avg_weekly_sale,
                avg_service_fee: action.payload.avg_service_fee,
                totalPeople: action.payload.totalPeople
            }
        case RESET_SCHEDULE:
            return {
                ...state,
                list: state.realList,
                spending_limit: { ...state.realSpendingLimit, amount: Number(state.realSpendingLimit.amount).toFixed(2) },
                totalPeople: state.realTotalPeople,
                avg_service_fee: state.real_avg_service_fee,
                avg_weekly_sale: state.real_avg_weekly_sale
            }
        case SUCCESS_UPDATE_SCHEDULES:
            return {
                ...state,
                realList: [...state.list],
                realSpendingLimit: { ...state.spending_limit },
                realTotalPeople: state.totalPeople,
                real_avg_weekly_sale: state.avg_weekly_sale,
                real_avg_service_fee: state.avg_service_fee
            }
        default:
            return state;
    }
}