import { SUCCESS_DASHBOARD } from './actionTypes';

const initialState = {
    info: {
        "totalCustomers": 0,
        "totalUniqueCustomers": 0,
        "totalReturningCustomers": 0
    }
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SUCCESS_DASHBOARD:
            return {
                ...state,
                info: action.payload
            }
        default:
            return state;
    }
}