import { SUCCESS_WAITERS, SUCCESS_ADD_WAITERS } from './actionTypes';

const initialState = {
    list: []
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SUCCESS_WAITERS:
            return {
                ...state,
                list: action.payload
            }
        case SUCCESS_ADD_WAITERS:
            return {
                ...state,
                list: [...state.list, action.payload]
            }
        default:
            return state;
    }
}