import { LOGIN_SUCCESS, BERYMO_PAYBALANCE, USER_ALREADY, GET_SETTINGS_SUCCESS, LOGIN_ERROR, PASSWORD_SUCCESS, SPENDING_LIMITS, SUPPORTED_CITIES } from './actionTypes';

const initialState = {
    info: {},
    error: false,
    errorMessage: "",
    already: false,
    settings: [],
    spending_limits: [],
    supportedcities: [],
    berymo_paybalance: 0
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                info: action.payload
            }
        case LOGIN_ERROR:
            return {
                ...state,
                error: action.payload.status,
                errorMessage: action.payload.message
            }
        case PASSWORD_SUCCESS:
            return {
                ...state,
                info: action.payload
            }
        case USER_ALREADY:
            return {
                ...state,
                already: action.payload
            }
        case GET_SETTINGS_SUCCESS:
            return {
                ...state,
                settings: action.payload
            }
        case SPENDING_LIMITS:
            return {
                ...state,
                spending_limits: action.payload
            }
        case SUPPORTED_CITIES:
            return {
                ...state,
                supportedcities: action.payload
            }
        case BERYMO_PAYBALANCE:
            return {
                ...state,
                berymo_paybalance: action.payload
            }
        default:
            return state;
    }
}