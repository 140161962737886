import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import './App.scss';

const loading = () => <div className="animated fadeIn pt-3 text-center"><div className="sk-spinner sk-spinner-pulse"></div></div>;

// Containers
const DefaultLayout = Loadable({
  loader: () => import('./containers/DefaultLayout'),
  loading
});

// Pages
const Login = Loadable({
  loader: () => import('./views/Pages/Login'),
  loading
});

const Forgot = Loadable({
  loader: () => import('./views/Pages/Forgot'),
  loading
});
const Signup = Loadable({
  loader: () => import('./views/Pages/Signup'),
  loading
});

const Smsconfirm = Loadable({
  loader: () => import('./views/Pages/Smsconfirm'),
  loading
});

const Changenumber = Loadable({
  loader: () => import('./views/Pages/Changenumber'),
  loading
});

const Finishsignup = Loadable({
  loader: () => import('./views/Pages/Finishsignup'),
  loading
});


const Reset = Loadable({
  loader: () => import('./views/Pages/Reset'),
  loading
});

class App extends Component {
  render() {
    return (
      <HashRouter>
          <Switch>
            <Route exact path="/login" name="Login Page" component={Login} />
            <Route path="/forgot" name="Forgot Password" component={Forgot} />
            <Route path="/signup" name="Sign Up" component={Signup} />
            <Route path="/sms-confirm" name="Sign Up" component={Smsconfirm} />
            <Route path="/change-number" name="Sign Up" component={Changenumber} />
            <Route path="/finish-signup" name="Sign Up" component={Finishsignup} />
            <Route path="/reset-password/:token" name="Reset Password" component={Reset} />
            <Route path="/" component={DefaultLayout} />
          </Switch>
      </HashRouter>
    );
  }
}

export default App;
