import { combineReducers } from 'redux';

import { reducer as userReducer } from './user/reducer';
import { reducer as menuReducer } from './menu/reducer';
import { reducer as customersReducer } from './customers/reducer';
import { reducer as waitersReducer } from './waiters/reducer';
import { reducer as dashboardReducer } from './dashboard/reducer';
import { reducer as scheduleReducer } from './schedule/reducer';
import { reducer as businessHoursReducer } from './businessHours/reducer';
import { reducer as happyHoursReducer } from './happyHours/reducer';
import { reducer as holidayReducer } from './holiday/reducer';
import { reducer as codeReducer } from './code/reducer';
import { reducer as ratingsReducer } from './ratings/reducer';
import { reducer as paymentReducer } from './payment/reducer';
import { reducer as invoicesReducer } from './invoices/reducer';

export const appReducer = combineReducers({
    user: userReducer,
    menu: menuReducer,
    customers: customersReducer,
    waiters: waitersReducer,
    dashboard: dashboardReducer,
    schedule: scheduleReducer,
    businessHours: businessHoursReducer,
    happyHours: happyHoursReducer,
    holiday: holidayReducer,
    code: codeReducer,
    ratings: ratingsReducer,
    payment: paymentReducer,
    invoices: invoicesReducer
});

const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT_SUCCESS') {
        state = undefined
    }
    return appReducer(state, action);
}

export default rootReducer;