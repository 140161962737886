import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import dashboard from './redux/reducers';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware, combineReducers } from "redux";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from './serviceWorker';

const rootReducer = combineReducers({ data: dashboard });
let store = createStore(
    rootReducer,
    applyMiddleware(thunk)
);

console.disableYellowBox = true
ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);
serviceWorker.unregister();