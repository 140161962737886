import { 
    SUCCES_BUSINESS_HOURS,
    UPDATE_BUSINESS_DAY,
    SUCCESS_UPDATE_BUSINESS,
    RESET_BUSINESS
} from './actionTypes'
;

const times=[
    {value:'1:00:00 am',label:'1:00:00 am'},
    {value:'2:00:00 am',label:'2:00:00 am'},
    {value:'3:00:00 am',label:'3:00:00 am'},
    {value:'4:00:00 am',label:'4:00:00 am'},
    {value:'5:00:00 am',label:'5:00:00 am'},
    {value:'6:00:00 am',label:'6:00:00 am'},
    {value:'7:00:00 am',label:'7:00:00 am'},
    {value:'8:00:00 am',label:'8:00:00 am'},
    {value:'9:00:00 am',label:'9:00:00 am'},
    {value:'10:00:00 am',label:'10:00:00 am'},
    {value:'10:15:00 am',label:'10:15:00 am'},
    {value:'11:00:00 am',label:'11:00:00 am'},
    {value:'11:50:00 am',label:'11:50:00 am'},
    {value:'11:59:00 am',label:'11:59:00 am'},
    {value:'12:30:00 pm',label:'12:30:00 pm'},
    {value:'1:00:00 pm',label:'1:00:00 pm'},
    {value:'2:00:00 pm',label:'2:00:00 pm'},
    {value:'3:00:00 pm',label:'3:00:00 pm'},
    {value:'4:00:00 pm',label:'4:00:00 pm'},
    {value:'5:00:00 pm',label:'5:00:00 pm'},
    {value:'6:00:00 pm',label:'6:00:00 pm'},
    {value:'7:00:00 pm',label:'7:00:00 pm'},
    {value:'8:00:00 pm',label:'8:00:00 pm'},
    {value:'9:00:00 pm',label:'9:00:00 pm'},
    {value:'10:00:00 pm',label:'10:00:00 pm'},
    {value:'11:00:00 pm',label:'11:00:00 pm'},
    {value:'11:59:00 pm',label:'11:59:00 pm'},
   ]

const initialState = {
    list: [],
    realList: [],
    times
    
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SUCCES_BUSINESS_HOURS:
            return {
                ...state,
                list: action.payload,
                realList: action.payload
            }
        case UPDATE_BUSINESS_DAY:
            return {
                ...state,
                list: action.payload
            }
        case SUCCESS_UPDATE_BUSINESS:
            return {
                ...state,
                realList: [...state.list]
            }
        case RESET_BUSINESS:
            return {
                ...state,
                list: state.realList
            }
        default:
            return state;
    }
}