import { SUCCESS_CODES, SUCCESS_DELETE_CODE, GENERATED_CODE } from './actionTypes';

const initialState = {
    list: [],
    total: 0
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SUCCESS_CODES:
            return {
                ...state,
                list: action.payload.berymo_codes,
                total: action.payload.total
            }
        case GENERATED_CODE:
            var list = [action.payload, ...state.list];
            if (list.length > 5) {
                list.pop();
            }
            return {
                ...state,
                list,
                total: state.total + 1
            }
        case SUCCESS_DELETE_CODE:
            return {
                ...state,
                list: action.payload,
                total: state.total - 1
            }
        default:
            return state;
    }
}