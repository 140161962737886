import { SUCCESS_CARDS, SUCCESS_COUPONS, SUCCESS_ADD_COUPON, REJECTED_ADD_COUPON } from './actionTypes';

const initialState = {
    cards: [],
    coupons: [],
    couponAmount: 0.00,
    couponError: false
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SUCCESS_CARDS:
            return {
                ...state,
                cards: action.payload
            }
        case SUCCESS_COUPONS:
            return {
                ...state,
                coupons: action.payload.coupons,
                couponAmount: action.payload.couponAmount
            }
        case SUCCESS_ADD_COUPON:
            return {
                ...state,
                coupons: [action.payload, ...state.coupons],
                couponAmount: Number(Number(state.couponAmount) + Number(action.payload.amount)).toFixed(2)
            }
        case REJECTED_ADD_COUPON:
            return {
                ...state,
                couponError: action.payload
            }
        default:
            return state;
    }
}